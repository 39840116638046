import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Login from '../scenes/Auth/Login';
// import SignUp from '../scenes/Auth/SignUp';
import routes from './routes';

export default function UnAuthenticatedRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path={routes.LOGIN} component={Login} />
        {/* <Route exact path={routes.SIGN_UP} component={SignUp} /> */}
        <Redirect to={routes.LOGIN} />
      </Switch>
    </Router>
  );
}
