import React, {Fragment, useEffect, useState} from 'react';
import {
    Collapse,
    IconButton,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import ChatIcon from '@material-ui/icons/Chat';
import EditIcon from '@material-ui/icons/Edit';
import CommentsPanel from './CommentsPanel';
import {Cell, Row, useExpanded, useTable} from 'react-table';
import {ShipmentWeekResponse} from '../../../API/types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {MenuAnchor} from '../types';
import DeleteIcon from "@material-ui/icons/DeleteForever";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {SwapVert} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    cell: {
        paddingLeft: '2px',
        paddingRight: '2px',
    },
    headerCell: {
        backgroundColor: 'white',
        paddingTop: '6px',
        paddingBottom: '6px',
        verticalAlign: 'bottom',
    },
    striped: {
        backgroundColor: theme.palette.action.hover,
    },
    period: {
        fontWeight: 'bold',
    },
    popover: {
        width: '300px',
        height: '300px',
    },
}));

interface TableBodyProps {
    shipments: ShipmentWeekResponse[];
    handleEditClick: (shipment: ShipmentWeekResponse) => void;
    handleDeleteClick: (shipment: ShipmentWeekResponse) => void;
    handleShipmentUpClick: (shipment: ShipmentWeekResponse, steps: number) => void;
    handleShipmentDownClick: (shipment: ShipmentWeekResponse, steps: number) => void;
    handleOpenMenu: (shipment: ShipmentWeekResponse) => (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleCloseMenu: () => void;
    getStylesForCell: (cell: Cell<ShipmentWeekResponse, any>) => { backgroundColor: string; } | { backgroundColor?: undefined; };
    columns: any;
    menuAnchor: MenuAnchor;
    handleCellClick: (cell: Cell<ShipmentWeekResponse, any>) => (event: React.MouseEvent) => void;
    onCommentSubmit: () => void;
    isCurrentWeek: boolean;
}

function ShipmentTableBody({shipments, handleEditClick, handleDeleteClick, handleShipmentUpClick, handleShipmentDownClick, handleOpenMenu, handleCloseMenu, getStylesForCell, columns, menuAnchor, handleCellClick, onCommentSubmit, isCurrentWeek}: TableBodyProps) {
    const classes = useStyles();

    const [mousePosition, setMousePosition] = useState([100,100]);

    const handleMouseClick = (event: MouseEvent) => setMousePosition([event.clientX,event.clientY]);

    useEffect(() => {
        window.addEventListener("click", handleMouseClick);
        return () => window.removeEventListener("click", handleMouseClick);
    });

    const handleCommentsClick = (id: string) => {
        toggleRowExpanded([id]);
        handleCloseMenu();
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        toggleRowExpanded,
    } = useTable(
        {
            columns,
            data: shipments,
            autoResetExpanded: false,
        },
        // useSortBy,
        useExpanded
    );

    const renderTotalQty = () => {
        const totalQTY = shipments
            .map(item => item.qty ? Number(item.qty) : 0)
            .reduce((prev, curr) => prev + curr, 0);

        return <div style={{ fontWeight: 'bold'}}>{totalQTY}</div>;
    };

    const renderTotalM3 = () => {
        const totalM3 = shipments
            .map(item => item.m3 ? Number(item.m3) : 0)
            .reduce((prev, curr) => prev + curr, 0);

        return <div style={{ fontWeight: 'bold'}}>{totalM3}</div>;
    };

    // @ts-ignore
    const SortableRow = SortableElement(({row}) => {
        prepareRow(row);
        return (
            <Fragment key={row.index}>
                <TableRow {...row.getRowProps()} key={row.original.id + '-row'}
                          className={row.index % 2 === 0 ? classes.striped : undefined}>
                    <TableCell key='menu'>
                        <IconButton
                            size="small"
                            onClick={handleOpenMenu(row.original)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <DragHandle />
                        <Menu
                            anchorReference={"anchorPosition"}
                            anchorPosition={{left: mousePosition[0], top: mousePosition[1]}}
                            keepMounted
                            open={menuAnchor.id === row.original.id}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={() => handleCommentsClick(row.id)}>
                                <ListItemIcon>
                                    <ChatIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Comments" />
                            </MenuItem>
                            <MenuItem onClick={() => handleEditClick(row.original)}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>
                            <MenuItem onClick={() => handleDeleteClick(row.original)}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Delete" />
                            </MenuItem>
                        </Menu>
                    </TableCell>
                    {row.cells.map((cell: Cell<ShipmentWeekResponse>) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableCell
                            {...cell.getCellProps()}
                            className={classes.cell}
                            style={getStylesForCell(cell)}
                            onClick={handleCellClick(cell)}
                        >
                            {cell.render('Cell')}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow key={row.original.id + '-comments'}>
                    <TableCell colSpan={columns.length + 1}>
                        <Collapse in={row.isExpanded} unmountOnExit>
                            <CommentsPanel
                                onCloseClick={() => toggleRowExpanded([row.id])}
                                shipmentId={row.original.id}
                                comments={row.original.comments}
                                onSubmit={onCommentSubmit}
                            />
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    });

    // @ts-ignore
    const SortableTableBody = SortableContainer(({rows}) => {
        if (rows.length > 0) {
            prepareRow(rows[0])
        }
        return (
            <TableBody {...getTableBodyProps}>
                {rows.map((row: Row, index: number) => (
                    <SortableRow key={`item-${index}`} index={index} row={row} />
                ))}
                {rows.length > 0 && <TableRow>
                    <TableCell style={{borderBottom: 0}} />
                    {rows[0].cells.map((cell: Cell) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableCell style={{borderBottom: 0}}>
                            { cell.column.id === 'qty' && renderTotalQty()}
                            { cell.column.id === 'm3' && renderTotalM3()}
                        </TableCell>
                    ))}
                </TableRow>}
            </TableBody>
        );
    });

    const DragHandle = SortableHandle(() =>
        <IconButton size="small" style={{marginLeft: "-8px"}}>
            <SwapVert />
        </IconButton>
    )

    // @ts-ignore
    const onSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex === newIndex) {
            return;
        }
        const movingShipment = rows[oldIndex].original;
        const steps = Math.abs(newIndex - oldIndex);

        if (newIndex < oldIndex) {
            handleShipmentUpClick(movingShipment, steps);
        } else {
            handleShipmentDownClick(movingShipment, steps);
        }
    }

    return (
        <Table stickyHeader size="small" padding="none" {...getTableProps} style={{marginBottom: '50px', "opacity": isCurrentWeek ? '1' : '0.9'}}>
            {rows.length > 0 &&
                <TableHead>
                    {headerGroups.map((headerGroup) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            <TableCell className={classes.headerCell} width={52} />
                            {headerGroup.headers.map((column) => (
                                // eslint-disable-next-line react/jsx-key
                                <TableCell
                                    className={clsx(classes.cell, classes.headerCell)}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography variant="body1">
                                            {column.render('Header')}
                                        </Typography>
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
            }
            {rows.length <= 0 && <div style={{marginLeft: "30px"}}>{"No orders found"}</div>}
            <SortableTableBody rows={rows} useDragHandle onSortEnd={onSortEnd}/>
        </Table>
    );
}

export default ShipmentTableBody;
