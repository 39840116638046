import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { default as Dashboard } from '../scenes/Dashboard';
import { default as ProjectList } from '../scenes/Project/scenes/List';
import { default as ProjectCreate } from '../scenes/Project/scenes/Create';
import { default as Users } from '../scenes/Users';
import { default as DataImport } from '../scenes/DataImport';
import routes from './routes';
import AuthenticatedPage from './Page/AuthenticatedPage';

const renderPage = (title: string, component: React.ComponentType) => {
  return function renderPageInternal(props: any) {
    return <AuthenticatedPage {...props} title={title} component={component} />;
  };
};

export default function AuthenticatedRouter() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={routes.DASHBOARD}
          render={renderPage('Dashboard', Dashboard)}
        />
        <Route
          exact
          path={routes.PROJECT.LIST}
          render={renderPage('Projects', ProjectList)}
        />
        <Route
          exact
          path={routes.PROJECT.CREATE}
          render={renderPage('Create project', ProjectCreate)}
        />
        <Route exact path={routes.USERS} render={renderPage('Users', Users)} />
        <Route
          exact
          path={routes.DATA_IMPORT}
          render={renderPage('Data import', DataImport)}
        />
        <Redirect to={routes.DASHBOARD} />
      </Switch>
    </Router>
  );
}
