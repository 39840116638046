import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {resolvePath} from "../../Dashboard/utils";

export type DatePickerProps = {
  formik: any;
  name: string;
  label: string;
  format: string;
};

export default function DatePicker(props: DatePickerProps) {
  const { formik, name } = props;
    return (
    <KeyboardDatePicker
      {...props}
      autoOk // closes the picker after selection
      fullWidth
      disableToolbar
      variant="inline"
      format={props.format}
      label={props.label}
      value={resolvePath(formik.values, name)}
      onChange={(date: MaterialUiPickersDate) => {
        const offset = -1 * (new Date()).getTimezoneOffset(); // We want start of day in UTC
        formik.setFieldValue(name, date ? date.startOf('day').add(offset, 'minutes').toDate() : null);
      }}
      error={resolvePath(formik.touched, name) && resolvePath(formik.errors, name)}
      helperText={resolvePath(formik.touched, name) && resolvePath(formik.errors, name)}
    />
  );
}
