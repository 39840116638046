import React, { useState, useEffect, useContext } from 'react';
import { Button, Collapse, makeStyles } from '@material-ui/core';
import CreateForm from './components/CreateForm';
import UsersTable from './components/UsersTable';
import { UsersResponse } from '../../API/types';
import API from '../../API';
import LoadingSpinner from '../components/LoadingSpinner';
import EditUserDialog from './components/EditUserDialog';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../UserContext';

const useStyles = makeStyles((theme) => ({
  createForm: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Users() {
  const classes = useStyles();
  const { user: authenticatedUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<UsersResponse[]>([]);

  useEffect(() => {
    reloadUsers();
  }, []);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const reloadUsers = () => {
    API.getUsers().then((response) => {
      if (response.isSuccessful) {
        setUsers(response.data);
      }

      setIsLoading(false);
    });
  };
  const handleCreateUser = () => {
    setIsFormVisible(false);
    reloadUsers();
  };
  const handleNewClick = () => setIsFormVisible(!isFormVisible);
  const handleEditUserClick = (user: UsersResponse) => setEditUser(user);
  const handleDeleteUserClick = async (user: UsersResponse) => {
    if (authenticatedUser.id === user.id) {
      enqueueSnackbar('Cannot delete yourself', {
        variant: 'error',
      });
    }

    const deleteResponse = await API.deleteUser(user.id);

    if (deleteResponse.isSuccessful) {
      enqueueSnackbar('User successfully deleted', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Oops, something went  wrong. Please try again later.', {
        variant: 'error',
      });
    }

    reloadUsers();
  };
  const handleRestoreUserClick = async (user: UsersResponse) => {
    const restoreResponse = await API.restoreUser({ id: user.id });

    if (restoreResponse.isSuccessful) {
      enqueueSnackbar('User successfully restored', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Oops, something went  wrong. Please try again later.', {
        variant: 'error',
      });
    }

    reloadUsers();
  };
  const [editUser, setEditUser] = useState<UsersResponse | null>(null);

  return (
    <>
      <div className={classes.createForm}>
        <Button variant="contained" color="primary" onClick={handleNewClick}>
          New user
        </Button>
        <Collapse unmountOnExit in={isFormVisible}>
          <CreateForm onSubmitClick={handleCreateUser} />
        </Collapse>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <UsersTable
          onEditClick={handleEditUserClick}
          onDeleteClick={handleDeleteUserClick}
          onRestoreClick={handleRestoreUserClick}
          users={users}
        />
      )}
      <EditUserDialog
        open={Boolean(editUser)}
        user={editUser}
        onUpdate={reloadUsers}
        onClose={() => setEditUser(null)}
      />
    </>
  );
}
