import React from 'react';
import {
  CheckboxProps as MuiCheckboxProps,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@material-ui/core';

export type SelectProps = MuiCheckboxProps & {
  formik: any;
  name: string;
  label: string;
  items: { label: string; value: string | number }[];
};

export default function Select(props: SelectProps) {
  const { formik, name } = props;
  return (
    <FormControl fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <MuiSelect
        value={formik.values[name]}
        name={name}
        onChange={formik.handleChange}
      >
        {props.items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}
