import React, { Fragment } from 'react';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { FormikHelpers, useFormik } from 'formik';
import { SignUpErrors } from '../types';
import TextField from '../../components/Formik/TextField';
import { Button, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { CreateUserForm, UsersResponse } from '../../../API/types';
import API from '../../../API';

export interface CreateFormProps {
  user?: UsersResponse;
  actionLabel?: string;
  onSubmitClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '5px',
  },
  input: {
    minWidth: '240px',
  },
}));

export default function CreateForm(props: CreateFormProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, onSubmitClick, actionLabel: submitLabel = 'Create' } = props;

  const validationSchema = yup.object({
    id: yup.number().nullable(),
    firstName: yup.string().max(255).required('First name is required'),
    lastName: yup.string().max(255).required('Last name is required'),
    email: yup.string().when('id', (id, schema) => {
      if (id) {
        return schema;
      }
      return schema
        .email('Enter a valid email')
        .max(50, 'Email should be a maximum of 50 characters length')
        .required('Email is required');
    }),
    password: yup.string().when('id', (id, schema) => {
      if (id) {
        return schema;
      }

      return schema
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required');
    }),
  });

  const formik = useFormik<CreateUserForm>({
    initialValues: {
      id: user?.id ?? null,
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (
      form: CreateUserForm,
      formikHelpers: FormikHelpers<CreateUserForm>
    ) => {
      let response;

      if (user) {
        response = await API.editUser(form);
      } else {
        response = await API.createUser(form);
      }

      if (response.isSuccessful) {
        const action = user ? 'updated' : 'created';
        enqueueSnackbar(`User successfully ${action}`, {
          variant: 'success',
        });

        formikHelpers.resetForm();
      } else {
        let errorMessage =
          'Whoops, something went wrong. Please try again later.';

        if (response.code === SignUpErrors.USER_ALREADY_EXISTS) {
          errorMessage = 'An user with this email already exists';
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }

      onSubmitClick();
    },
  });

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
        <Grid container spacing={3}>
          <Grid item container xs={12} spacing={3}>
            <Grid item>
              <TextField
                className={classes.input}
                formik={formik}
                name="firstName"
                label="First name"
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                formik={formik}
                name="lastName"
                label="Last name"
              />
            </Grid>
          </Grid>
          {!user && (
            <Grid item container xs={12} spacing={3}>
              <Grid item>
                <TextField
                  className={classes.input}
                  formik={formik}
                  name="email"
                  label="E-mail"
                />
              </Grid>
              <Grid item>
                <TextField
                  className={classes.input}
                  formik={formik}
                  name="password"
                  label="Password"
                  type="password"
                />
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Button type="submit" variant="outlined" color="primary">
              {submitLabel}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}
