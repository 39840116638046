import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import { Tooltip, Typography } from '@material-ui/core';
import { ProjectsResponse } from '../../../../API/types';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  cell: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  headerCell: { paddingTop: '6px', paddingBottom: '6px' },
}));

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

export interface ProjectsTableProps {
  projects: ProjectsResponse[];
}

export default function ProjectsTable(props: ProjectsTableProps) {
  const classes = useStyles();

  const renderBoolean = (value: boolean | null) => {
    if (value) {
      return <CheckIcon />;
    }

    return <ClearIcon />;
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table stickyHeader size="small" padding="none">
          <TableHead>
            <TableRow>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Year</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Activity date</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Op. ID</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Type</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Contact</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Client ID</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">PO date</Typography>
              </TableCell>
              <TableCell
                align="right"
                className={clsx(classes.cell, classes.headerCell)}
              >
                <Typography variant="body1">PO value</Typography>
              </TableCell>
              <TableCell
                align="right"
                className={clsx(classes.cell, classes.headerCell)}
              >
                <Typography variant="body1">Sold value</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Invoice date</Typography>
              </TableCell>
              <TableCell className={clsx(classes.cell, classes.headerCell)}>
                <Typography variant="body1">Invoice ref</Typography>
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                <Tooltip arrow title="PO Sent" placement="top">
                  <Typography variant="body1">P</Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                <Tooltip arrow title="TPO sent" placement="top">
                  <Typography variant="body1">T</Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                <Tooltip arrow title="Booking received" placement="top">
                  <Typography variant="body1">B</Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                <Tooltip arrow title="Loaded" placement="top">
                  <Typography variant="body1">L</Typography>
                </Tooltip>
              </TableCell>
              <TableCell className={classes.headerCell} align="center">
                <Tooltip arrow title="Delivered" placement="top">
                  <Typography variant="body1">D</Typography>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.projects.map((row) => (
              <StyledTableRow key={row.id}>
                <TableCell className={classes.cell} size="small">
                  {row.year}
                </TableCell>
                <TableCell className={classes.cell}>
                  {row.activityDate}
                </TableCell>
                <TableCell className={classes.cell}>
                  {row.operationRefId}
                </TableCell>
                <TableCell className={classes.cell}>{row.type}</TableCell>
                <TableCell className={classes.cell}>
                  {row.contactName}
                </TableCell>
                <TableCell className={classes.cell}>
                  {row.clientRefId}
                </TableCell>
                <TableCell className={classes.cell}>{row.poDate}</TableCell>
                <TableCell className={classes.cell} align="right">
                  {row.poValue ? `${row.poValue}€` : ''}
                </TableCell>
                <TableCell className={classes.cell} align="right">
                  {row.soldValue ? `${row.soldValue}€` : ''}
                </TableCell>
                <TableCell className={classes.cell}>
                  {row.invoiceDate}
                </TableCell>
                <TableCell className={classes.cell}>{row.invoiceRef}</TableCell>
                <TableCell align="center">
                  {renderBoolean(row.isPoSent)}
                </TableCell>
                <TableCell align="center">
                  {renderBoolean(row.isTpoSent)}
                </TableCell>
                <TableCell align="center">
                  {renderBoolean(row.isBookingReceived)}
                </TableCell>
                <TableCell align="center">
                  {renderBoolean(row.isLoaded)}
                </TableCell>
                <TableCell align="center">
                  {renderBoolean(row.isDelivered)}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
