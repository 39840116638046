import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import { UserContext } from './UserContext';
import { UserDetails } from './API/types';
import API from './API';
import AuthenticatedRouter from './router/AuthenticatedRouter';
import UnAuthenticatedRouter from './router/UnAuthenticatedRouter';
import LoadingSpinner from './scenes/components/LoadingSpinner';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/et';
import dayjs from 'dayjs';
dayjs.locale('et');

dayjs.extend(isoWeek);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserDetails>({ id: 0 });

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      setIsLoading(false);
    }

    if (token && !user?.id) {
      API.getUser().then((response) => {
        if (response.isSuccessful) {
          setUser(response.data);
          setIsLoading(false);
        } else {
          localStorage.removeItem('token');
          setIsLoading(false);
        }
      });
    }
  }, [user]);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <CssBaseline />
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <UserContext.Provider value={{ user, setUser }}>
              {user.id ? <AuthenticatedRouter /> : <UnAuthenticatedRouter />}
            </UserContext.Provider>
          )}
        </SnackbarProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
