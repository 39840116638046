const routes = {
  DASHBOARD: '/dashboard',
  PROJECT: {
    LIST: '/project/list',
    CREATE: '/project/create',
  },
  USERS: '/users',
  TEST: '/test',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  DATA_IMPORT: '/data-import',
};

export default routes;
