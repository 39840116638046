import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { UsersResponse } from '../../../API/types';
import CreateForm from './CreateForm';

export interface EditUserDialogProps {
  onClose: () => void;
  onUpdate: () => void;
  open: boolean;
  user: UsersResponse | null;
}

export default function EditUserDialog(props: EditUserDialogProps) {
  const { open, onClose, onUpdate, user } = props;

  const handleSubmit = () => {
    onClose();
    onUpdate();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      {user && (
        <React.Fragment>
          <DialogTitle>Edit user</DialogTitle>
          <DialogContent>
            <CreateForm
              user={user}
              actionLabel="Update"
              onSubmitClick={() => handleSubmit()}
            />
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
}
