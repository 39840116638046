import React, { Fragment, useEffect, useState } from 'react';
import { Button, Collapse, makeStyles } from '@material-ui/core';
import CreateShipmentForm from './components/CreateShipmentForm';
import API from '../../API';
import {ClientsResponse, CreateShipmentRequest, EditShipmentRequest, ShipmentWeekResponse} from '../../API/types';
// import useMercure, { ShipmentUpdateEvent } from '../../utils/useMercure';
import dayjs from 'dayjs';
import ShipmentTable from './components/ShipmentTable';
import EditShipmentDialog from './components/EditShipmentDialog';
import {getCurrentPeriod, getWeekRange, getWeekRangeStrings} from "../../utils/weekUtils";
import {ShipmentForm} from './types';
import {useSnackbar} from 'notistack';

const useStyles = makeStyles((theme) => ({
  createForm: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [period, setPeriod] = useState<dayjs.Dayjs>(getCurrentPeriod);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [clients, setClients] = useState<ClientsResponse[]>([]);
  const [shipments, setShipments] = useState<ShipmentWeekResponse[]>([]);
  const [editShipment, setEditShipment] = useState<ShipmentWeekResponse | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    API.getClients().then((response) => {
      if (response.isSuccessful) {
        setClients(response.data);
      }
    });
  }, []);

  useEffect(() => {
    refreshShipments();
  }, [period]);

  // useMercure<ShipmentUpdateEvent>(
  //   'shipment/update',
  //   (payload) => refreshShipment(payload.shipmentId),
  //   [shipments]
  // );

  const refreshShipments = () => {
    const weeks = getWeekRangeStrings(period)

    API.getShipments(period.year(), weeks).then((response) => {
      if (response.isSuccessful) {
        setShipments(response.data);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  // Used for live-updating comments. Mercure event will pass updated shipment ID.
  // function refreshShipment(id: number) {
  //   const index = shipments.findIndex((shipment) => shipment.id === id);
  //
  //   if (index !== -1) {
  //     API.getShipmentDetails(id).then((response) => {
  //       if (response.isSuccessful) {
  //         const clonedShipments = [...shipments];
  //         clonedShipments[index] = response.data;
  //
  //         setShipments(clonedShipments);
  //       }
  //     });
  //   }
  // }

  const onSubmit = async (form: ShipmentForm) => {
    const payload: CreateShipmentRequest | EditShipmentRequest = {
      ...form,
    };

    let response;

    if (form.id) {
      response = await API.editShipment(payload as EditShipmentRequest);
    } else {
      response = await API.createShipment(payload as CreateShipmentRequest);
    }

    if (response.isSuccessful) {
      enqueueSnackbar(`Order ${form.id ? 'updated' : 'created'}`, {
        variant: 'success',
      });
      handleSubmitClick();
    } else {
      let message = form.id
          ? 'Failed to update the order'
          : 'Failed to create a new order';
      if (response.message) {
        message = response.message
      }
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
  }

  const handleNewClick = () => setIsFormVisible(!isFormVisible);
  const handleSubmitClick = () => {
    setIsFormVisible(false);
    refreshShipments();
  };

  const handleNextWeekClick = () => {
    let newPeriod = period;
    do {
      newPeriod = newPeriod.add(1, 'week');
    } while (getWeekRange(newPeriod).length < 3)

    setPeriod(newPeriod);
  }
  const handlePrevWeekClick = () => {
    let newPeriod = period;
    do {
      newPeriod = newPeriod.subtract(1, 'week');
    } while (getWeekRange(newPeriod).length < 3)

    setPeriod(newPeriod);
  }
  const handleWeekSelect = (period: dayjs.Dayjs) => setPeriod(period);

  const handleEditShipmentClick = (shipment: ShipmentWeekResponse) =>
    setEditShipment(shipment);

  const handleDeleteShipmentClick = (id: number) => {
    API.deleteShipment(id).then(() => refreshShipments());
  }

  const handleShipmentUpClick = (id: number, steps: number) => {
    API.moveShipmentUp(id, steps).then(() => refreshShipments());
  }

  const handleShipmentDownClick = (id: number, steps: number) => {
    API.moveShipmentDown(id, steps).then(() => refreshShipments());
  }

  return (
    <Fragment>
        <Fragment>
          <div className={classes.createForm}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewClick}
            >
              New order
            </Button>
            <Collapse unmountOnExit in={isFormVisible}>
              <CreateShipmentForm
                clients={clients}
                onSubmit={onSubmit}
                onFormClose={handleSubmitClick}
              />
            </Collapse>
          </div>
          <ShipmentTable
            clients={clients}
            period={period}
            shipments={shipments}
            onSubmit={onSubmit}
            onNextWeekClick={handleNextWeekClick}
            onPrevWeekClick={handlePrevWeekClick}
            onWeekSelect={handleWeekSelect}
            onEditShipmentClick={handleEditShipmentClick}
            onDeleteShipmentClick={handleDeleteShipmentClick}
            onShipmentUpClick={handleShipmentUpClick}
            onShipmentDownClick={handleShipmentDownClick}
            onCommentSubmit={refreshShipments}
            isLoading={isLoading}
            setLoading={setIsLoading}
          />
          <EditShipmentDialog
            open={Boolean(editShipment)}
            onSubmit={onSubmit}
            clients={clients}
            shipment={editShipment}
            onClose={() => setEditShipment(null)}
          />
        </Fragment>
    </Fragment>
  );
}
