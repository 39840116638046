import React, {ChangeEvent, useState} from 'react';
import {ClientsResponse, ShipmentCellColors, ShipmentComment, ShipmentWeekResponse} from '../../../API/types';
import {
  Button, FormControl,
  Grid,
  IconButton,
  makeStyles, MenuItem,
  Paper,
  Popover, Select,
  TableContainer,
  Toolbar, Tooltip,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeIcon from '@material-ui/icons/Home';
import {Cell, useExpanded, useTable, UseTableRowProps,} from 'react-table';
import dayjs from 'dayjs';
import {
  getCurrentPeriod,
  getSelectableWeeks,
  getWeekRange, getWeekString,
  isWeekNumber
} from "../../../utils/weekUtils";
import ShipmentTableBody from './ShipmentTableBody';
import {ShipmentForm} from '../types';
import {GithubPicker} from 'react-color';
import LoadingSpinner from "../../components/LoadingSpinner";

export interface ShipmentTableProps {
  clients: ClientsResponse[];
  period: dayjs.Dayjs;
  onSubmit: (form: ShipmentForm) => Promise<void>;
  onNextWeekClick: () => void;
  onPrevWeekClick: () => void;
  onWeekSelect: (period: dayjs.Dayjs) => void;
  onEditShipmentClick: (shipment: ShipmentWeekResponse) => void;
  onDeleteShipmentClick: (id: number) => void;
  onShipmentUpClick: (id: number, steps: number) => void;
  onShipmentDownClick: (id: number, steps: number) => void;

  shipments: ShipmentWeekResponse[];
  onCommentSubmit: () => void;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

const columns: {
  Header: string;
  Cell?: ({
    row,
  }: {
    row: UseTableRowProps<ShipmentWeekResponse>;
  }) => string | number | null;
  accessor: keyof ShipmentWeekResponse;
}[] = [
  { accessor: 'customerNo', Header: 'Customer no' },
  { accessor: 'customerName', Header: 'Customer' },
  { accessor: 'pod', Header: 'POD' },
  { accessor: 'ourRef', Header: 'OUR ref#' },
  { accessor: 'origWeek', Header: 'Week' },
  { accessor: 'qty', Header: 'QTY' },
  { accessor: 'vessel', Header: 'Vessel/booking' },
  { accessor: 'gate', Header: 'Gate' },
  { accessor: 'product', Header: 'Product' },
  { accessor: 'loadingTimes', Header: 'Loading', Cell: ({row}) => {
      if (!row.original.loadingTimes.length) {
        return '';
      }

      const loadingTimeDisplay = row.original.loadingTimes.map((loadingTime) => (
          (loadingTime.time ? dayjs(loadingTime.time).format('DD.MM') : '')
                  + ((loadingTime.time && loadingTime.qty !== null) ? ' - ' : '')
                  + (loadingTime.qty !== null ? loadingTime.qty : '')
      ));
      return loadingTimeDisplay.join(', ');
    }
  },
  { accessor: 'closingDoc', Header: 'Closing doc' , Cell: ({row}) => (row.original.closingDoc ? dayjs(row.original.closingDoc).format('DD.MM') : '')},
  { accessor: 'etd', Header: 'ETD' , Cell: ({row}) => (row.original.etd ? dayjs(row.original.etd).format('DD.MM') : '')},
  { accessor: 'eta', Header: 'ETA' , Cell: ({row}) => (row.original.eta ? dayjs(row.original.eta).format('DD.MM') : '')},
  { accessor: 'vgm', Header: 'VGM', Cell: ({row}) => (row.original.vgm ? 'ok' : '')},
  { accessor: 'm3', Header: 'M3' },
  { accessor: 'comments', Header: 'Comment', Cell: ({ row }) => (renderCommentCell(row.original.comments))}
];

const renderCommentCell = (comments: ShipmentComment[]) => {
  comments.sort((first, second) => second.id - first.id);

  if (comments.length > 0) {
    let commentBody = comments[0].body;

    if (commentBody.length > 55) {
      commentBody = `${commentBody.substring(0, 55)}...`;
    }

    if (comments.length === 1) {
      return commentBody;
    }

    return `${commentBody}\n\n(+${comments.length - 1})`;
  }

  return null;
}

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 30px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  cell: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  headerCell: {
    backgroundColor: 'white',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  striped: {
    backgroundColor: theme.palette.action.hover,
  },
  period: {
    fontWeight: 'bold',
  },
  popover: {
    width: '300px',
    height: '300px',
  },
}));

export default function ShipmentTable(props: ShipmentTableProps) {
  const classes = useStyles();

  const [colorpicker, setColorpicker] = useState<{
    row: { id: number; field: string };
    position: {
      top: number;
      left: number;
    };
  } | null>(null);

  const [menuAnchor, setMenuAnchor] = useState<{
    id: number | null;
    anchorEl: null | HTMLElement;
  }>({ id: null, anchorEl: null });

  const handleConfirmColor = (color: string) => {
    const id = colorpicker?.row.id as number;
    const field = colorpicker?.row.field as string;

    const shipment = props.shipments.find((s) => s.id === id);
    if (shipment) {
      const updatedCell: ShipmentCellColors = {
        cellName: field,
        hex: color
      };

      const filteredCellColors = shipment.colors ? shipment.colors.filter((c) => c.cellName !== field) : [];
      const colors: ShipmentCellColors[] = [...filteredCellColors, updatedCell];
      const updatedShipment = {...shipment, colors};
      props.onSubmit(updatedShipment as ShipmentForm);
    }

    setColorpicker(null);
  };

  const handleClearColor = () => {
    const id = colorpicker?.row.id as number;
    const field = colorpicker?.row.field as string;
    const shipment = props.shipments.find((s) => s.id === id);

    if (shipment) {
      const updatedCellColors = shipment.colors.filter((c) => c.cellName !== field);
      const updatedShipment = {...shipment, colors: updatedCellColors}
      props.onSubmit(updatedShipment as ShipmentForm)
    }

    setColorpicker(null);
  };

  const getStylesForCell = (cell: Cell<ShipmentWeekResponse>) => {
    const field = cell.column.id;
    if (cell.row.original.colors) {
      const coloredCell = cell.row.original.colors.find((color) => color.cellName === field);

      if (coloredCell) {
        return { backgroundColor: coloredCell.hex }
      }
    }

    return {};
  };

  const handleCellClick =
    (cell: Cell<ShipmentWeekResponse>) => (event: React.MouseEvent) => {
      if (window!!.getSelection()!!.toString()) {
        return;
      }

      const id = cell.row.original.id;
      const field = cell.column.id;
      const { clientX, clientY } = event;

      setColorpicker({
        row: { id, field },
        position: {
          top: clientY,
          left: clientX,
        },
      });
    };

  const { toggleAllRowsExpanded } = useTable(
    {
      columns,
      data: props.shipments,
      autoResetExpanded: false,
    },
    useExpanded
  );

  const handleNextWeekClick = () => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onNextWeekClick();
  };

  const handleWeekSelect = (event: ChangeEvent<any>) => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onWeekSelect(dayjs(event.target.value));
  };

  const handleTodaySelect = () => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onWeekSelect(getCurrentPeriod());
  };

  const handlePrevWeekClick = () => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onPrevWeekClick();
  };

  const handleOpenMenu =
    (shipment: ShipmentWeekResponse) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor({ id: shipment.id, anchorEl: event.currentTarget })
    };

  const handleCloseMenu = () => {
    setMenuAnchor({ id: null, anchorEl: null });
  };

  const handleEditClick = (shipment: ShipmentWeekResponse) => {
    handleCloseMenu();
    props.onEditShipmentClick(shipment);
  };

  const handleDeleteClick = (shipment: ShipmentWeekResponse) => {
    handleCloseMenu();
    if(window.confirm('Are you sure you want to delete order ' + shipment.ourRef + '?'))
      props.onDeleteShipmentClick(shipment.id);
  };

  const handleShipmentUpClick = (shipment: ShipmentWeekResponse, steps: number) => {
    handleCloseMenu();
    props.onShipmentUpClick(shipment.id, steps);
  };

  const handleShipmentDownClick = (shipment: ShipmentWeekResponse, steps: number) => {
    handleCloseMenu();
    props.onShipmentDownClick(shipment.id, steps);
  };

  const ShipmentTableTitle = (weekNumber: number, isCurrentWeek: boolean) => {
    return (
        <div className={classes.tableHeader}>
          <h2 style={{"opacity": isCurrentWeek ? '1' : '0.9'}}>Week {weekNumber}</h2>
        </div>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Popover
        className={classes.popover}
        open={colorpicker !== null}
        anchorReference="anchorPosition"
        anchorPosition={colorpicker?.position as any}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setColorpicker(null)}
      >
        <GithubPicker
          triangle={'hide'}
          colors={['#EEEBD0', '#AAEFDF', '#74A4BC', '#44BBA4', '#DE9151', '#E5D352', '#DA9F93']}
          onChangeComplete={(c) => handleConfirmColor(c.hex)}
        />
        <Button variant="contained" onClick={handleClearColor} size={'small'} fullWidth>
          Clear
        </Button>
      </Popover>
      <Toolbar>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton size="small" onClick={handlePrevWeekClick}>
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <FormControl>
                  <Select
                      value={props.period.format('YYYY-MM-DD')}
                      onChange={handleWeekSelect}
                      disableUnderline
                      style={{fontWeight: "bold"}}
                  >
                    {getSelectableWeeks(props.period).map((option) =>
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Tooltip title="Today">
                  <IconButton size="small" onClick={handleTodaySelect}>
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={handleNextWeekClick}>
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
        {getWeekRange(props.period).map((week) => (
            <>{ShipmentTableTitle(week, isWeekNumber(week, props.period))}
              {props.isLoading ? (
                  <LoadingSpinner />
              ) : (
                <TableContainer key={week}>
                  <ShipmentTableBody
                      key={week}
                      shipments={props.shipments.filter((shipment) => shipment.week === getWeekString(week))}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      handleShipmentUpClick={handleShipmentUpClick}
                      handleShipmentDownClick={handleShipmentDownClick}
                      handleOpenMenu={handleOpenMenu}
                      handleCloseMenu={handleCloseMenu}
                      getStylesForCell={getStylesForCell}
                      columns={columns}
                      menuAnchor={menuAnchor}
                      handleCellClick={handleCellClick}
                      onCommentSubmit={props.onCommentSubmit}
                      isCurrentWeek={isWeekNumber(week, props.period)}
                  />
               </TableContainer>
            )}
          </>
        ))}
    </Paper>
  );
}
