import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { ClientsResponse, ShipmentWeekResponse } from '../../../API/types';
import CreateShipmentForm from './CreateShipmentForm';
import {ShipmentForm} from '../types';

export interface EditShipmentDialogProps {
  onClose: () => void;
  onSubmit: (form: ShipmentForm) => Promise<void>;
  open: boolean;
  clients: ClientsResponse[];
  shipment: ShipmentWeekResponse | null;
}

export default function EditShipmentDialog(props: EditShipmentDialogProps) {
  const { open, onClose, clients, shipment } = props;

  const handleClose = () => onClose();

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      {shipment && (
        <React.Fragment>
          <DialogTitle>Edit order {shipment.ourRef}</DialogTitle>
          <DialogContent>
            <CreateShipmentForm
              shipment={shipment}
              actionLabel="Update"
              clients={clients}
              onSubmit={props.onSubmit}
              onFormClose={() => handleClose()}
            />
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
}
