import { makeStyles, Grid, Typography, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import dayjs from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import * as yup from 'yup';
import API from '../../../API';
import {
  CreateShipmentCommentRequest,
  ShipmentComment,
} from '../../../API/types';
import TextField from '../../components/Formik/TextField';

export interface CommentsPanelProps {
  shipmentId: number;
  onCloseClick: () => void;
  comments: ShipmentComment[];
  onSubmit: () => void;
}

const validationSchema = yup.object({
  body: yup.string().required('Comment is required'),
});

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: blue[50],
    padding: theme.spacing(2),
  },
  row: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  timeStamp: {
    marginRight: theme.spacing(2),
  },
  formItem: {
    padding: 0,
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  sendButton: {
    marginLeft: '10px',
  },
  closeButton: {
    width: '15%',
  },
}));

export default function CommentsPanel(props: CommentsPanelProps) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<CreateShipmentCommentRequest>({
    initialValues: {
      shipmentId: props.shipmentId,
      body: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (
      form: CreateShipmentCommentRequest,
      formikHelpers: FormikHelpers<CreateShipmentCommentRequest>
    ) => {
      const createResponse = await API.createShipmentComment(form);

      if (createResponse.isSuccessful) {
        enqueueSnackbar('Comment added', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to add a new comment', {
          variant: 'error',
        });
      }

      formikHelpers.resetForm();
      props.onSubmit();
    },
  });

  return (
    <div className={classes.container}>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs={8} className={classes.formItem}>
            <TextField
              size="small"
              formik={formik}
              name="body"
              label="Comment"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              className={classes.sendButton}
              type="submit"
              color="primary"
              variant="contained"
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
      {props.comments.map((comment) => (
        <React.Fragment key={comment.id}>
          <Grid container className={classes.row}>
            <Grid item xs={2}>
              <Typography
                variant="caption"
                component="span"
                className={classes.timeStamp}
              >
                {dayjs(comment.createdAt).format('D. MMM. YYYY HH:mm')}
              </Typography>
              <Typography component="span" variant="body2">
                {comment.firstName} {`${comment.lastName.charAt(0)}.`}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body2">{comment.body}</Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
      <div className={classes.closeButtonContainer}>
        <Button
          className={classes.closeButton}
          onClick={props.onCloseClick}
          color="primary"
          variant="outlined"
        >
          CLOSE
        </Button>
      </div>
    </div>
  );
}
