import HttpClient, { HttpResponse } from '../utils/HttpClient';
import {
  ClientsResponse,
  CreateProjectRequest,
  CreateShipmentCommentRequest,
  CreateShipmentRequest,
  CreateUserForm,
  EditShipmentRequest,
  EditUserForm,
  LoginResponse,
  ProjectsResponse,
  RestoreUserForm,
  ShipmentDetailsResponse,
  ShipmentWeekResponse,
  SignUpResponse,
  UserDetails,
  UsersResponse,
} from './types';

export default {
  login: async (
    email: string,
    password: string
  ): Promise<HttpResponse<LoginResponse>> => {
    const response = await HttpClient.post('/auth/login', {
      email,
      password,
    });

    return response.data;
  },

  signUp: async (
    email: string,
    password: string
  ): Promise<HttpResponse<SignUpResponse>> => {
    const response = await HttpClient.post('/auth/signup', {
      email,
      password,
    });

    return response.data;
  },
  getUser: async (): Promise<HttpResponse<UserDetails>> => {
    const response = await HttpClient.get('/user/me');

    return response.data;
  },
  createUser: async (payload: CreateUserForm): Promise<HttpResponse<void>> => {
    const response = await HttpClient.post('/auth/signup', payload);

    return response.data;
  },
  editUser: async (payload: EditUserForm): Promise<HttpResponse<void>> => {
    const response = await HttpClient.put('/user', payload);

    return response.data;
  },
  deleteUser: async (id: number): Promise<HttpResponse<void>> => {
    const response = await HttpClient.delete(`/user/${id}`);

    return response.data;
  },
  restoreUser: async (
    payload: RestoreUserForm
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.post(`/user/restore`, payload);

    return response.data;
  },
  getUsers: async (): Promise<HttpResponse<UsersResponse[]>> => {
    const response = await HttpClient.get('/user/list');

    return response.data;
  },
  getClients: async (): Promise<HttpResponse<ClientsResponse[]>> => {
    const response = await HttpClient.get('/contact/clients');

    return response.data;
  },
  createProject: async (
    payload: CreateProjectRequest
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.post('/project/create', payload);

    return response.data;
  },
  getProjects: async (): Promise<HttpResponse<ProjectsResponse[]>> => {
    const response = await HttpClient.get('/project/list');

    return response.data;
  },
  getShipments: async (
    year: number,
    weeks: string[]
  ): Promise<HttpResponse<ShipmentWeekResponse[]>> => {
    const response = await HttpClient.get(
      `/shipment/list?year=${year}&week=${weeks.join(',')}`
    );

    return response.data;
  },
  getShipmentDetails: async (
    id: number
  ): Promise<HttpResponse<ShipmentDetailsResponse>> => {
    const response = await HttpClient.get(`/shipment/${id}`);

    return response.data;
  },
  createShipment: async (
    payload: CreateShipmentRequest
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.post('/shipment', payload);

    return response.data;
  },
  editShipment: async (
    payload: EditShipmentRequest
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.put(`/shipment/${payload.id}`, payload);

    return response.data;
  },
  deleteShipment: async (
      id: number
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.delete(`/shipment/${id}`);

    return response.data;
  },
  moveShipmentUp: async (
      id: number,
      steps: number
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.post(`/shipment/${id}/up/${steps}`);

    return response.data;
  },
  moveShipmentDown: async (
      id: number,
      steps: number
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.post(`/shipment/${id}/down/${steps}`);

    return response.data;
  },
  createShipmentComment: async (
    payload: CreateShipmentCommentRequest
  ): Promise<HttpResponse<void>> => {
    const response = await HttpClient.post('/shipment/comment', payload);

    return response.data;
  },
  importExcel: async (file: File): Promise<HttpResponse<void>> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await HttpClient.post('/import/excel', formData);

    return response.data;
  },
  importAccessDb: async (file: File): Promise<HttpResponse<void>> => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await HttpClient.post('/import/accessdb', formData);

    return response.data;
  },
};
