import { CircularProgress, Fade } from '@material-ui/core';
import React from 'react';

export default function LoadingSpinner() {
  return (
    <Fade
      in={true}
      style={{
        transitionDelay: '800ms',
      }}
      unmountOnExit
    >
      <CircularProgress />
    </Fade>
  );
}
