import React, {Fragment} from 'react';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import {FieldArray, FormikProvider, useFormik} from 'formik';
import {Button, IconButton, makeStyles} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '../../components/Formik/TextField';
import {
  ClientsResponse,
  ShipmentWeekResponse,
} from '../../../API/types';
import { ShipmentForm } from '../types';
import {getCurrentPeriod, getWeekString} from "../../../utils/weekUtils";
import Checkbox from '../../components/Formik/Checkbox';
import DatePicker from '../../components/Formik/Datepicker';
import {GridAddIcon} from "@material-ui/data-grid";
import ClearIcon from "@material-ui/icons/Clear";

const validationSchema = yup.object({
  customerNo: yup.string().nullable().max(255),
  customerName: yup.string().nullable().max(255),
  pod: yup.string().nullable().max(255),
  ourRef: yup.string().nullable().max(255),
  week: yup.string().required().max(3),
  year: yup.string().required().min(4).max(4),
  qty: yup.string().nullable().max(255),
  vessel: yup.string().nullable().max(255),
  gate: yup.string().nullable().max(255),
  product: yup.string().nullable().max(255)
});

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  cell: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  headerCell: { paddingTop: '6px', paddingBottom: '6px' },
}));

export interface CreateShipmentFormProps {
  onFormClose: () => void;
  onSubmit: (form: ShipmentForm) => Promise<void>;
  clients: ClientsResponse[];
  actionLabel?: string;
  shipment?: ShipmentWeekResponse;
}

export default function CreateShipmentForm(props: CreateShipmentFormProps) {
  const classes = useStyles();
  const { shipment } = props;

  const handleFormSubmit = (shipment: ShipmentForm) => {
    props.onSubmit(shipment);
  }

  const formik = useFormik<ShipmentForm>({
    initialValues: {
      id: shipment?.id ?? null,
      customerNo: shipment?.customerNo ?? null,
      customerName: shipment?.customerName ?? null,
      pod: shipment?.pod ?? null,
      ourRef: shipment?.ourRef ?? null,
      week: shipment?.week ?? getWeekString(getCurrentPeriod().isoWeek()),
      year: shipment?.year ?? getCurrentPeriod().year().toString(),
      qty: shipment?.qty ?? null,
      vessel: shipment?.vessel ?? null,
      gate: shipment?.gate ?? null,
      product: shipment?.product ?? null,
      closingDoc: shipment?.closingDoc ?? null,
      etd: shipment?.etd ?? null,
      eta: shipment?.eta ?? null,
      vgm: shipment?.vgm ?? false,
      m3: shipment?.m3 ?? null,
      colors: shipment?.colors ?? [],
      loadingTimes: shipment && shipment.loadingTimes.length ? shipment.loadingTimes : [{'time': null, 'qty': null}],
    },
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              formik={formik}
              name="customerNo"
              label="Customer no"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
                freeSolo
                options={props.clients.map((client) => client.name)}
                onChange={(event, value) => {
                  formik.setFieldValue("customerName", value);
                }}
                value={formik.values.customerName?.length ? formik.values.customerName : undefined}
                renderInput={(params) =>
                    <TextField
                        name="customerName"
                        label="Customer"
                        formik={formik}
                        value
                        {...params}
                    />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} name="pod" label="POD" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField
              formik={formik}
              name="ourRef"
              label="OUR ref#"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField name="week" label="Week" formik={formik} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField name="year" label="Year" formik={formik} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} type="number" name="qty" label="QTY" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField
              formik={formik}
              name="vessel"
              label="Vessel/booking"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} name="gate" label="Gate" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField
              formik={formik}
              name="product"
              label="Product"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormikProvider value={formik}>
              <FieldArray
                  name="loadingTimes"
                  render={({ remove, push }) => (
                      <Grid container spacing={2}>
                        {formik.values.loadingTimes.map((loadingTime, index) => (
                            <Grid item xs={10} key={index}>
                              <Grid container spacing={2}>
                              <Grid item xs={formik.values.loadingTimes.length > 1 ? 7 : 12}>
                                <DatePicker formik={formik} name={`loadingTimes[${index}].time`} label="Loading" format="DD.MM.YY" />
                              </Grid>
                                {formik.values.loadingTimes.length > 1 && (
                                    <>
                                      <Grid item xs={4}>
                                        <TextField formik={formik} type="number" name={`loadingTimes[${index}].qty`} label="QTY" />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton>
                                          <ClearIcon onClick={() => remove(index)}/>
                                        </IconButton>
                                      </Grid>
                                    </>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        <Grid item xs={formik.values.loadingTimes.length > 1 ? 12 : 2}>
                          <IconButton>
                            <GridAddIcon onClick={() => push({ time: null, qty: null })}/>
                          </IconButton>
                        </Grid>
                      </Grid>
                  )}
           />
          </FormikProvider>
          </Grid>
          <Grid item xs={4}>
            <DatePicker formik={formik} name="closingDoc" label="Closing doc" format="DD.MM.YY" />
          </Grid>
          <Grid item xs={4}>
            <DatePicker formik={formik} name="etd" label="ETD" format="DD.MM.YY" />
          </Grid>
          <Grid item xs={4}>
            <DatePicker formik={formik} name="eta" label="ETA" format="DD.MM.YY" />
          </Grid>
          <Grid item xs={4}>
            <Checkbox formik={formik} name="vgm" label="VGM" />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} type="number" name="m3" label="M3" fullWidth />
          </Grid>
        </Grid>
        <Button
          className={classes.submit}
          type="submit"
          variant="outlined"
          color="primary"
        >
          {props.actionLabel ?? 'Submit'}
        </Button>
      </form>
    </Fragment>
  );
}
