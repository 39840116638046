import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button/Button';
import ProjectsTable from './ProjectsTable';
import { useHistory } from 'react-router-dom';
import routes from '../../../../router/routes';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { ProjectsResponse } from '../../../../API/types';
import API from '../../../../API';
import { Fade } from '@material-ui/core';

export default function List() {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectsResponse[]>([]);
  const history = useHistory();

  useEffect(() => {
    API.getProjects().then((res) => {
      if (res.isSuccessful) {
        setProjects(res.data);
      }
      setIsLoading(false);
    });
  }, []);

  const handleNewClick = () => history.push(routes.PROJECT.CREATE);

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Fade in={true}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewClick}
              >
                New project
              </Button>
            </Grid>
            <Grid item xs={12}>
              <ProjectsTable projects={projects} />
            </Grid>
          </Grid>
        </Fade>
      )}
    </Fragment>
  );
}
