import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import RestoreIcon from '@material-ui/icons/Restore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTable, UseTableRowProps } from 'react-table';
import { UsersResponse } from '../../../API/types';

export interface UsersTableProps {
  users: UsersResponse[];
  onEditClick: (user: UsersResponse) => void;
  onDeleteClick: (user: UsersResponse) => void;
  onRestoreClick: (user: UsersResponse) => void;
}

const columns: {
  Header: string;
  Cell?: ({
    row,
  }: {
    row: UseTableRowProps<UsersResponse>;
  }) => string | number | null;
  accessor: keyof UsersResponse;
}[] = [
  { accessor: 'firstName', Header: 'First name' },
  { accessor: 'lastName', Header: 'Last name' },
  { accessor: 'email', Header: 'E-mail' },
  {
    accessor: 'isDeleted',
    Header: 'Status',
    Cell: ({ row }) => (row.original.isDeleted ? 'Inactive' : 'Active'),
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  cell: {
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  headerCell: {
    backgroundColor: 'white',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  striped: {
    backgroundColor: theme.palette.action.hover,
  },
  period: {
    fontWeight: 'bold',
  },
  popover: {
    width: '300px',
    height: '300px',
  },
}));

export default function UsersTable(props: UsersTableProps) {
  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<{
    id: number | null;
    anchorEl: null | HTMLElement;
  }>({ id: null, anchorEl: null });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: props.users,
    });

  const handleOpenMenu =
    (user: UsersResponse) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor({ id: user.id, anchorEl: event.currentTarget });
    };

  const handleCloseMenu = () => {
    setMenuAnchor({ id: null, anchorEl: null });
  };

  const handleEditClick = (user: UsersResponse) => {
    handleCloseMenu();
    props.onEditClick(user);
  };

  const handleDeleteClick = (user: UsersResponse) => {
    handleCloseMenu();
    props.onDeleteClick(user);
  };

  const handleRestoreClick = (user: UsersResponse) => {
    handleCloseMenu();
    props.onRestoreClick(user);
  };

  const actionMenuItems = [
    { name: 'Edit', action: handleEditClick, Icon: EditIcon },
    { name: 'Delete', action: handleDeleteClick, Icon: DeleteIcon },
  ];

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table stickyHeader size="small" padding="none" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={classes.headerCell} />
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    className={clsx(classes.cell, classes.headerCell)}
                    {...column.getHeaderProps()}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography variant="body1">
                        {column.render('Header')}
                      </Typography>
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.original.id}>
                  <TableRow
                    {...row.getRowProps()}
                    className={index % 2 === 0 ? classes.striped : undefined}
                  >
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={handleOpenMenu(row.original)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={
                          menuAnchor.id === row.original.id
                            ? menuAnchor.anchorEl
                            : null
                        }
                        keepMounted={false}
                        open={menuAnchor.id === row.original.id}
                        onClose={handleCloseMenu}
                      >
                        {!row.original.isDeleted &&
                          actionMenuItems.map((item) => {
                            const Icon = item.Icon;
                            return (
                              <MenuItem
                                key={item.name}
                                onClick={() => item.action(row.original)}
                              >
                                <ListItemIcon>
                                  <Icon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                              </MenuItem>
                            );
                          })}
                        {row.original.isDeleted && (
                          <MenuItem
                            onClick={() => handleRestoreClick(row.original)}
                          >
                            <ListItemIcon>
                              <RestoreIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Restore" />
                          </MenuItem>
                        )}
                      </Menu>
                    </TableCell>
                    {row.cells.map((cell) => (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell
                        {...cell.getCellProps()}
                        className={classes.cell}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
