import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '../config';

const HttpClient = Axios.create({
  baseURL: API_BASE_URL,
});

HttpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

HttpClient.interceptors.response.use(
  (response) => {
    const wrappedResponse: HttpResponse<any> = {
      isSuccessful: true,
      code: '',
      message: '',
      data: response.data,
    };

    response.data = wrappedResponse;

    return response;
  },
  (error: AxiosError) => {
    const { config } = error;
    const response = error.response!;
    const urlBlackList = ['/auth/login', '/auth/logout', '/auth/'];

    if (response.status === 401 && !urlBlackList.includes(config.url ?? '')) {
      window.location.reload();
    }

    const wrappedResponse: HttpResponse<any> = {
      isSuccessful: false,
      code: error.response?.data?.code,
      message: error.response?.data?.message,
      data: error?.response,
    } as HttpResponse<any>;

    response.data = wrappedResponse;

    return response;
  }
);

export interface HttpResponse<T> {
  isSuccessful: boolean;
  code: string;
  message: string;
  data: T;
}

export default HttpClient;
