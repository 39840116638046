import React, { useState, useEffect, Fragment } from 'react';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import TextField from '../../../components/Formik/TextField';
import { Button, InputAdornment } from '@material-ui/core';
import Checkbox from '../../../components/Formik/Checkbox';
import Select from '../../../components/Formik/Select';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import routes from '../../../../router/routes';
import dayjs from 'dayjs';
import DatePicker from '../../../components/Formik/Datepicker';
import { ClientsResponse, CreateProjectRequest } from '../../../../API/types';
import { CreateProjectForm } from '../../types';
import API from '../../../../API';

const validationSchema = yup.object({
  year: yup.string().nullable().max(255),
  activityDate: yup.string().nullable().max(255),
  operationRefId: yup.string().nullable().max(255),
  type: yup.string().nullable().max(255),
  contactId: yup.number().nullable(),
  clientRefId: yup.string().nullable().max(255),
  poDate: yup.string().nullable().max(255),
  poValue: yup.string().nullable().max(255),
  soldValue: yup.string().nullable().max(255),
  invoiceDate: yup.string().nullable().max(255),
  invoiceRef: yup.string().nullable().max(255),
  isPoSent: yup.boolean(),
  isTpoSent: yup.boolean(),
  isBookingReceived: yup.boolean(),
  isLoaded: yup.boolean(),
  isDelivered: yup.boolean(),
});

export default function Create() {
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState<ClientsResponse[]>([]);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<CreateProjectForm>({
    initialValues: {
      year: '2021',
      activityDate: dayjs().toDate(),
      operationRefId: '',
      type: 'CPO',
      contactId: null,
      clientRefId: '',
      poDate: dayjs().toDate(),
      poValue: '',
      soldValue: '',
      invoiceDate: dayjs().toDate(),
      invoiceRef: '',
      isPoSent: false,
      isTpoSent: false,
      isBookingReceived: false,
      isLoaded: false,
      isDelivered: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (form: CreateProjectForm) => {
      const payload: CreateProjectRequest = {
        ...form,
        activityDate: convertDateForRequest(form.activityDate),
        poDate: convertDateForRequest(form.poDate),
        invoiceDate: convertDateForRequest(form.invoiceDate),
      };
      const createResponse = await API.createProject(payload);

      if (createResponse.isSuccessful) {
        enqueueSnackbar('Project created', {
          variant: 'success',
        });
        history.push(routes.PROJECT.LIST);
      } else {
        enqueueSnackbar('Failed to create a new project', {
          variant: 'error',
        });
      }
    },
  });

  const convertDateForRequest = (date: Date | null) =>
    date ? dayjs(date).format('YYYY-MM-DD') : null;

  useEffect(() => {
    setIsLoading(true);
    API.getClients().then((res) => {
      if (res.isSuccessful) {
        setClients(res.data);
        const firstClientId = res.data[0]?.id;

        if (firstClientId) {
          formik.initialValues.contactId = firstClientId;
        }
      }

      setIsLoading(false);
    });
  }, []);

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Select
                name="year"
                label="Operation year"
                formik={formik}
                items={[
                  { label: '2020', value: '2020' },
                  { label: '2021', value: '2021' },
                  { label: '2022', value: '2022' },
                  { label: '2023', value: '2023' },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                formik={formik}
                name="activityDate"
                label="Last activity date"
                format="DD.MM.YY"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                formik={formik}
                name="operationRefId"
                label="Operation Ref ID"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                name="type"
                label="Type of order"
                formik={formik}
                items={[
                  { value: 'PRA', label: 'Product analyses' },
                  { value: 'CPO', label: 'Client Purchase Order' },
                  { value: 'PFI', label: 'Pro Forma Invoice' },
                  { value: 'QTE', label: 'Sales Quote' },
                  { value: 'SMP', label: 'Sample fo Marketing Purposes' },
                  { value: 'SPO', label: 'Supplier Purchase Order' },
                  { value: 'TPO', label: 'Transport Purchase Order' },
                  { value: 'OGL', label: 'Outgoing Letter' },
                  { value: 'CTR', label: 'Contract' },
                  { value: 'HRA', label: 'Human Resource Contract' },
                  { value: 'QCS', label: 'Quote Calculation Sheet' },
                  { value: 'ORC', label: 'Order Confirmation' },
                  { value: 'SCD', label: 'Supply Chain Declaration' },
                  { value: 'BDN', label: 'Board decisions' },
                  { value: 'SCP', label: 'Sales Commission Payout' },
                  { value: 'SOC', label: 'Sales Order Confirmation' },
                  { value: 'MND', label: 'Management decisions' },
                  { value: 'EML', label: 'E-mail' },
                  { value: 'LOI', label: 'Letter Of Intent' },
                  { value: 'ALS', label: 'Analytical Sheet' },
                  { value: 'CRN', label: 'Credit Note' },
                  { value: 'CMI', label: 'Commercial Invoice' },
                  { value: 'TES', label: 'Travel Expenses Statement' },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                name="contactId"
                label="Client name"
                formik={formik}
                items={clients.map((client) => ({
                  label: client.name,
                  value: client.id,
                }))}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                formik={formik}
                name="clientRefId"
                label="Client ID"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                formik={formik}
                name="poDate"
                label="PO date"
                format="DD.MM.YY"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                formik={formik}
                name="poValue"
                label="PO value"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                formik={formik}
                name="soldValue"
                label="Sold value"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                formik={formik}
                name="invoiceDate"
                label="Invoice date"
                format="DD.MM.YY"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                formik={formik}
                name="invoiceRef"
                label="Invoice Ref"
                fullWidth
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Checkbox
                formik={formik}
                name="isPoSent"
                label="Suppliers PO sent"
              />
            </Grid>
            <Grid item xs={4}>
              <Checkbox formik={formik} name="isTpoSent" label="TPO sent" />
            </Grid>
            <Grid item xs={4}>
              <Checkbox
                formik={formik}
                name="isBookingReceived"
                label="Booking received"
              />
            </Grid>
            <Grid item xs={4}>
              <Checkbox formik={formik} name="isLoaded" label="Loaded" />
            </Grid>
            <Grid item xs={4}>
              <Checkbox formik={formik} name="isDelivered" label="Delivered" />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      )}
    </Fragment>
  );
}
