import React from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import { useSnackbar } from 'notistack';
import API from '../../API';
import { HttpResponse } from '../../utils/HttpClient';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '30px',
  },
  button: {
    width: '250px',
  },
}));

export default function DataImport() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = (
    uploadHandler: (file: File) => Promise<HttpResponse<void>>
  ) => {
    return async (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = validateUpload(e.target.files);

      if (uploadedFile) {
        const importResponse = await uploadHandler(uploadedFile);
        handleUploadResponse(importResponse);
      }
      e.target.value = '';
    };
  };

  const handleUploadResponse = (response: HttpResponse<void>) => {
    if (response.isSuccessful) {
      enqueueSnackbar('File upload successful', { variant: 'success' });
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const validateUpload = (upload: FileList | null): File | null => {
    if (!upload) {
      enqueueSnackbar('No file recieved. Please try again.', {
        variant: 'error',
      });
      return null;
    }

    if (upload.length > 1) {
      enqueueSnackbar('File upload is restricted to one file at a time', {
        variant: 'error',
      });
      return null;
    }

    const uploadedFile = upload[0];

    return uploadedFile;
  };

  return (
    <>
      <Typography variant="h5" className={classes.title}>
        Update the database by uploading the latest source files
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={10}>
          <Button
            className={classes.button}
            startIcon={<BackupIcon />}
            variant="contained"
            component="label"
          >
            Upload .xslx
            <input
              hidden
              type="file"
              name="file"
              accept=".xlsx"
              onChange={handleUpload(API.importExcel)}
            />
          </Button>
        </Grid>
        <Grid item xs={10}>
          <Button
            className={classes.button}
            startIcon={<BackupIcon />}
            variant="contained"
            component="label"
          >
            Upload .accdb
            <input
              hidden
              type="file"
              name="file"
              accept=".accdb"
              onChange={handleUpload(API.importAccessDb)}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
